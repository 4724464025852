$(function() {
    $('.datepicker').datepicker({
        format: 'dd/mm/yyyy',
        language: 'fr',
        clearBtn: true
    });

    var paysElm = $("select[name=pays]");
    var searchPromoteurElm = $('#search-promoteur');

    if(parseInt(paysElm.val(), 10) > -1) {
        updateTypeahead(paysElm.val());
    }

    paysElm.change(function() {
        var pays = parseInt(paysElm.val(), 10);

        if (pays === -1) {
            $("input[name=promoteur]").val(-1);
            searchPromoteurElm.val('');
            searchPromoteurElm.attr('placeholder', 'Veuillez sélectionner un pays pour la recherche par promoteur');
            $("select[name=source]").html('<option value="-1" selected>N\'importe</option>');
            $("select[name=region]").html('<option value="-1" selected>N\'importe</option>');
        } else {
            searchPromoteurElm.attr('placeholder', '');
            $("select[name=source]").load('/ajax/sources/' + pays);
            $("select[name=region]").load('/ajax/regions/' + pays);
        }

        updateTypeahead(pays);
    });

    searchPromoteurElm.bind('typeahead:select', function(ev, suggestion) {
        $("input[name=promoteur]").val(suggestion.id);
    });

    searchPromoteurElm.change(function() {
        if($(this).val() === '') {
            $("input[name=promoteur]").val(-1);
        }
    });

    function updateTypeahead(pays) {
        if (pays === -1 || pays === '-1') {
            searchPromoteurElm.typeahead('destroy');
            return;
        }

        var promoteurs = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: '/ajax/promoteurs/' + pays + '/%QUERY%',
                wildcard: '%QUERY%'
            },
        });

        searchPromoteurElm.typeahead({
            hint: true,
            highlight: true,
            minLength: 3
        }, {
            name: 'promoteurs',
            source: promoteurs,
            display: function(data) {
                return data.nom  // Input value to be set when you select a suggestion.
            },
            templates: {
                empty: [
                    '<div class="list-group search-results-dropdown"><div class="list-group-item">Aucun trouvé.</div></div>'
                ],
                header: [
                    '<div class="list-group search-results-dropdown">'
                ],
                suggestion: function(data) {
                    return '<div style="cursor: pointer; font-weight:normal; margin-top:-10px !important;" class="list-group-item">' + data.nom + '</div></div>'
                }
            }
        });
    }
});